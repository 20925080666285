import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import { pagesLinks } from "../../../pages-data/_V2/common/links"
import styles from "./styles.module.scss"

export default {
	desktopTitle: <span>Автоматизация столовой&nbsp;</span>,
	price: "от 1990 ₽/мес",
	subTitle: (
		<p>
			Программа Quick Resto позволяет быстро обслуживать большие потоки
			посетителей — кассир успевает принять до 10 заказов в минуту. Предложи
			гостям разнообразие блюд, а система управления столовой поможет эффективно
			вести номенклатуру, бухгалтерский учёт и учёт продуктов.
		</p>
	),
	figure: (
		<StaticImage
			className={styles.image}
			src="./assets/canteen-presentation.png"
			alt="автоматизация столовой"
			objectFit="contain"
			objectPosition="center"
			placeholder="blurred"
			quality={90}
		/>
	),
	cta: pagesLinks.registration.text,
	ctaLink: pagesLinks.registration,
	desktopCtaDetails:
		"За 10 минут, без дорогого оборудования, с удобным интерфейсом. И две недели бесплатно.",
}
