import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import styles from "./styles.module.scss"
import implementationStyles from "../common/implementation-feature/styles.module.scss"

import { pagesLinks } from "../../common/links"

export default [
	{
		title: "Программа для столовой в облаке",
		description: (
			<div className={styles.smallDescription}>
				<p>
					Управляй всеми процессами бизнеса в одном онлайн-кабинете в браузере.
					В облаке можно в&nbsp;любой&nbsp;момент просмотреть документы склада
					—&nbsp;приходные накладные и акты, отредактировать права доступа
					сотрудников, оценить продажи.
				</p>
				<p>
					Отчёты доступны на экране смартфона — используй мобильное приложение
					для владельца заведения. Об опасных операциях с кассой и новых отзывах
					предупредят моментальные пуш-уведомления.
				</p>
				<p>
					Подходит для автоматизации столовой на предприятии, кафе, ресторана,
					сети заведений общепита.
				</p>
			</div>
		),

		img: (
			<StaticImage
				className={styles.image1}
				src="./assets/canteen-feature-1.png"
				alt="программа автоматизации столовой"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		reverse: true,
		className: styles.feature1,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.index.href,
	},
	{
		title: "Удобная CRM-система",
		description: (
			<div className={styles.smallDescription}>
				<p>
					Система автоматизации столовой поддерживает любые программы лояльности.
					Предложи гостям акцию «Комбо», чтобы увеличить средний чек или введи
					скидку в вечернее время на оставшуюся продукцию. Отчёт по акциям
					покажет, какие из них действительно работают.
				</p>
				<p>
					Если формат позволяет, можно легко вести свою клиентскую базу —
					предлагать индивидуальные скидки для групп гостей и бонусную
					программу. Гость чувствует заботу и возвращается снова и снова.
				</p>
			</div>
		),

		img: (
			<StaticImage
				className={styles.image2}
				src="./assets/canteen-feature-2.png"
				alt="crm для столовой"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		className: styles.feature2,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.index.href,
	},
	{
		title: "Меню для столовой",
		description: (
			<>
				<p>
					В программе меню можно создать в нескольких вариантах: по дням недели,
					вегетарианское, детское, воскресное. У одного блюда может быть
					несколько версий техкарт — чтобы подобрать наиболее прибыльный
					вариант.
				</p>
				<p>
					Ведение техкарт в Quick Resto позволяет автоматизировать учёт
					продуктов и быстро рассчитывать КБЖУ блюд. Программа для столовой
					упрощает калькуляцию. Если изменилась закупочная цена, система сообщит
					об этом. Ингредиенты подорожали — повышай цену оперативно.
				</p>
			</>
		),

		img: (
			<StaticImage
				className={styles.image3}
				src="./assets/canteen-feature-3.png"
				alt="меню для столовой"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		reverse: true,

		className: styles.feature3,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.index.href,
	},
	{
		title: "Пользовательские типы оплат",
		mobileTitle: (
			<span className={styles.longWordTitle}>Пользовательские типы оплат</span>
		),
		description: (
			<div className={styles.smallDescription}>
				<p>
					Принимай оплату тем способом, который удобен гостю — наличными, картой
					или смешанным типом. Если используешь бонусную программу — гость может
					оплатить бонусами до 100% суммы.
				</p>
				<p>
					Доступны гибкие настройки. У супа низкая маржинальность и на него
					невыгодно давать скидку — исключи блюдо из бонусной программы.
				</p>
				<p data-mt="small">
					Автоматизация с Quick Resto даёт возможность создать собственный тип
					оплаты — для питания сотрудников. Это предотвращает махинации со
					скидками персонала.
				</p>
			</div>
		),

		img: (
			<StaticImage
				className={styles.image4}
				src="./assets/canteen-feature-4.png"
				alt="Пользовательские типы оплат"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		className: styles.feature4,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.index.href,
	},
	{
		title: "Формат Quick Food",
		description: (
			<div>
				<p>
					Кассовый терминал Quick Resto ориентирован на&nbsp;скорость обслуживания.
					Чтобы быстрее находить блюда, используй голосовой набор. Модификаторы
					будут отображаться автоматически. Любую акцию, доступную гостю, можно
					применить в&nbsp;два клика.
				</p>
				<p>
					Меню столовой в&nbsp;программе обновляется автоматически, поэтому блюда
					в&nbsp;стоп-листе не&nbsp;нужно проверять отдельно.
				</p>
				<p>
					Не&nbsp;упускай дополнительную прибыль&nbsp;&mdash; подсказки на&nbsp;терминале напомнят
					предложить топпинг к&nbsp;мороженому.
				</p>
			</div>
		),

		img: (
			<StaticImage
				className={styles.image5}
				src="./assets/canteen-feature-5.png"
				alt="Quick Food"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		reverse: true,

		className: styles.feature5,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.index.href,
	},
	{
		title: "Онлайн управление кухней и учет продуктов питания",
		description: (
			<div className={styles.smallDescription}>
				<p>
					Автоматизация поможет защитить склад от недобросовестных действий
					сотрудников. Тонкие настройки прав доступа, автоматическое списание со
					склада, простая инвентаризация и другие возможности для эффективного
					учёта продуктов.
				</p>
				<p data-mt="small">
					В программе для столовой есть инструменты для технолога и
					бухгалтера-калькулятора —&nbsp;автоматический расчёт себестоимости,
					веса, КБЖУ блюда, расчёт нормы закладки. Управлять кухней можно онлайн
					и быстро вносить необходимые изменения.
				</p>
			</div>
		),

		img: (
			<StaticImage
				className={styles.image6}
				src="./assets/canteen-feature-6.png"
				alt="Пользовательские типы оплат"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		className: styles.feature6,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.index.href,
	},
	{
		title: "Поддержка 54-ФЗ",
		description: (
			<p className={styles.smallDescription}>
				Терминал Quick Resto работает как онлайн-касса и поддерживает 54-ФЗ. Это
				значит, что все чеки заведения автоматически отправляются в налоговую.
			</p>
		),

		img: (
			<StaticImage
				className={styles.image7}
				src="./assets/canteen-feature-7.png"
				alt="Пользовательские типы оплат"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		reverse: true,
		className: styles.feature7,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.index.href,
	},
	{
		title: "Внедрение системы автоматизации под ключ",
		description: (
			<>
				<p>От первой продажи до масштабирования.</p>
				<p>Quick Resto даст все инструменты:</p>
				<ul>
					<li>Приложения для управления и продаж</li>
					<li>Отчёты по продажам, блюдам, работе сотрудников</li>
					<li>Складской учёт и технологические карты</li>
					<li>CRM-система и аналитика ресторана</li>
					<li>Сервис: установка, настройка, обучение</li>
					<li>Круглосуточная техподдержка</li>
				</ul>
			</>
		),
		img: (
			<StaticImage
				className={implementationStyles.image}
				src="../common/implementation-feature/implementation-feature.png"
				alt="Алексей Гаврилов"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
		className: implementationStyles.feature,
		theme: "white",
		backdrop: (
			<div className={implementationStyles.featureTopRightTrapezeBackdrop} />
		),
	},
]
